.AidenSection {
    padding-top: 150px;
    padding-bottom: 150px;
    padding-right: 0px;
    padding-left: 0px;
    .levTextSection {
        padding-left: 50px!important;
    }
    .ugtestiVideoThumb {
        text-align: center;
        .ugVideoIcon {
            margin-top: -29%;
            cursor: pointer;
        }
        .ugVideoThumb {
            img {
                border: 5px solid #ffffff!important;
            }
        }
    }
    h2 {
        font-size: 3.3vw !important;
        line-height: 4vw !important;
        white-space: pre-line;
        letter-spacing: 0.03rem;
        opacity: 0.9;
    }
    h3 {
        font-size: 1.8vw;
        letter-spacing: 0.03rem;
        line-height: 2.4vw;
        white-space: pre-line;
        max-width: 630px;
        margin-top: 16px;
        margin-bottom: 25px;
    }
    p {
        font-size: 18px;
        font-family: gilroy-semibold;
        letter-spacing: 0px;
        line-height: 28px;
        white-space: pre-line;
        max-width: 630px;
        margin-bottom: 40px;
    }
    Button, a[href] {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:active, a[href]:active {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:focus, a[href]:focus {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:hover, a[href]:hover {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0!important;
        color: #000000;
        font-weight: 600;
    }
}
.profileBuilderImg {
    margin-top: -4%;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
    .AidenSection {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 15px;
        padding-left: 15px;
        .levTextSection {
            padding: 15px!important;
        }
        Button, a[href] {
            margin-bottom: 0px;
            font-size: 18px;
            padding: 13px 25px;
        }
        Button:hover, a[href]:hover {
            margin-bottom: 0px;
            font-size: 18px;
            padding: 13px 25px;
        }
        h2 {
            font-size: 32px!important;
            line-height: 45px!important;
        }
        h3 {
            font-size: 5vw;
            letter-spacing: 0.03rem;
            line-height: 7.5vw;
        }
    }
}