.teamUpSection {
    .advisorSection {
        padding: 7% 5% 7% 7%;
    }
    padding-top: 130px;
    padding-bottom: 130px;
}
.AidenSection {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-right: 5px;
    padding-left: 50px;
    .ugtestiVideoThumb {
        text-align: center;
        .ugVideoIcon {
            margin-top: -29%;
            cursor: pointer;
        }
        .ugVideoThumb {
            img {
                border: 5px solid #ffffff!important;
            }
        }
    }
    h2 {
        font-size: 3.3vw !important;
        line-height: 4vw !important;
        white-space: pre-line;
        letter-spacing: 0.03rem;
        opacity: 0.9;
    }
    h3 {
        font-size: 1.8vw;
        letter-spacing: 0.03rem;
        line-height: 2.4vw;
        white-space: pre-line;
        max-width: 630px;
        margin-top: 16px;
        margin-bottom: 25px;
    }
    p {
        font-size: 18px;
        font-family: gilroy-semibold;
        letter-spacing: 0px;
        line-height: 28px;
        white-space: pre-line;
        max-width: 630px;
        margin-bottom: 40px;
    }
    Button, a[href] {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:active, a[href]:active {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:focus, a[href]:focus {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0;
        color: #000000;
        font-weight: 600;
    }
    Button:hover, a[href]:hover {
        letter-spacing: 0.03rem;
        font-size: 18px;
        padding: 10px 24px;
        border-radius: 8px;
        background: #ffffff;
        border: 0!important;
        color: #000000;
        font-weight: 600;
    }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .teamUpSection {
        .advisorSection {
            padding: 7% 7% 7% 7%;
        }
    }
    .AidenSection {
        padding-top: 10px;
        padding-bottom: 0px;
        padding-right: 15px;
        padding-left: 15px;
        .ugtestiVideoThumb {
            text-align: center;
            .ugVideoIcon {
                margin-top: -29%;
                cursor: pointer;
            }
            .ugVideoThumb {
                img {
                    border: 5px solid #ffffff!important;
                }
            }
        }
        h2 {
            font-size: 32px!important;
            line-height: 45px!important;
            white-space: pre-line;
            letter-spacing: 0.03rem;
            opacity: 0.9;
        }
        h3 {
            font-size: 5vw;
            letter-spacing: 0.03rem;
            line-height: 35px;
            white-space: pre-line;
            max-width: 630px;
            margin-top: 16px;
            margin-bottom: 25px;
        }
        p {
            font-size: 18px;
            font-family: gilroy-semibold;
            letter-spacing: 0px;
            line-height: 28px;
            white-space: pre-line;
            max-width: 630px;
            margin-bottom: 40px;
        }
        Button, a[href] {
            letter-spacing: 0.03rem;
            font-size: 18px;
            padding: 13px 25px;
            border-radius: 8px;
            background: #ffffff;
            border: 0;
            color: #000000;
            font-weight: 600;
            margin-bottom: 0px;
        }
    }
    .teamUpSection {
        padding-top: 15px;
        padding-bottom: 45px;
        padding-right: 15px;
        padding-left: 15px;
    }
}